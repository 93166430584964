import { Link } from "react-router-dom";
import Header from "./header";
import Footer from './footer';

export default function Resume() {
  return (
    <>
      <Header title="Résumé" className="vh-50" />
      <section className="container-fluid vh-50">
        <div
          className="d-flex flex-column align-items-center justify-content-center"
        >
          <div className="row vw-md-90 vw-lg-75 bg-bermuda-grey-90 py-4 px-3">
            <h2>Web Developer | Problem Solver</h2>
            <p className="fs-5">
              I’m a front end developer with a diverse background, focused on
              building meaningful web experiences using clean, performant code,
              and an eye for detail. I'm fascinated with new ways to connect and
              distribute information, and bring people together.
            </p>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col">
            <p className="fs-3 text-center">
              <a href="#experience"
                ><i className="bi bi-arrow-down-circle ms-2"></i
              ></a>
            </p>
          </div>
        </div>
      </section>
      <section className="container-fluid my-5" id="experience">
        <div
          className="d-flex flex-column align-items-center justify-content-center h-100"
          id="developer"
        >
          <div className="row vw-md-90 bg-santa-fe-90">
            <div
              className="col-12 col-md-12 col-lg-7 col-xl-8 d-flex flex-column justify-content-center px-5 pb-5"
            >
              <h3 className="font-code">#Experience</h3>
              <dl>
                <dt>
                  <strong>Frontend Consultant</strong> | Hub Group<br />
                  <small><em>Contract, October 2023 to Present</em></small>
                </dt>
                <dd>
                  <ul>
                    <li>Oversee technical aspects of multiple brand consolidation efforts</li>
                    <li>Plan website content migrations, redirect strategies, and form integrations</li>
                    <li>Maintain existing corporate website</li>
                    <li>Build new web features in coordination with marketing efforts</li>
                    <li>Optimize website performance and suggest improvements</li>
                    <li>Mentor junior developers</li>
                    <li>Skills: 
                      HTML, CSS, JavaScript, WordPress, React, PHP,
                      Figma
                    </li>
                  </ul>
                </dd>
                <dt>
                  <strong>Founder / Developer</strong> | Grove Avenue Web
                  Works<br />
                  <small><em>October 2016 to Present</em></small>
                </dt>
                <dd>
                  <ul>
                    <li>
                      Sole proprietor company providing services to small and
                      medium sized businesses
                    </li>
                    <li>Emphasis on limited scope, smaller budgets</li>
                    <li>
                      HTML, CSS, JavaScript, WordPress, Webflow, React, PHP,
                      Photoshop
                    </li>
                  </ul>
                </dd>
                <dt>
                  <strong>Front End Developer</strong> | Eight Bit Studios,
                  LLC<br />
                  <small
                    ><em
                      >Part time, Contract - August 2020 to August 2023</em
                    ></small
                  >
                </dt>
                <dd>
                  <ul>
                    <li>
                      Worked with account and project managers, designers, UX
                      and QA teams to meet client requirements
                    </li>
                    <li>
                      Contributed to projects including data visualization,
                      video streaming, and education
                    </li>
                    <li>
                      Built UI components with React, JavaScript, Sass / CSS and
                      HTML
                    </li>
                    <li>
                      TypeScript, NextJS, Node.js, Rails, Git, Figma, Sketch,
                      Material UI, APIs
                    </li>
                  </ul>
                </dd>
                <dt>
                  <strong>UI Developer</strong> | Unicorn Road, LLC<br />
                  <small
                    ><em
                      >Part time, Contract - May 2022 to April 2023</em
                    ></small
                  >
                </dt>
                <dd>
                  <ul>
                    <li>
                      Built client websites in Webflow, with custom JavaScript
                      and CSS
                    </li>
                    <li>
                      Integrated third party webhook and marketing services
                    </li>
                    <li>Worked with distributed teams and clients directly</li>
                    <li>
                      Contributed to projects including aerospace, agriculture,
                      and e-commerce
                    </li>
                    <li>HTML, Bootstrap, Zapier, Salesforce, HubSpot</li>
                  </ul>
                </dd>
                <dt>
                  <strong>Front End Developer</strong> | Codeverse<br />
                  <small><em>Contract - May 2021 to March 2022</em></small>
                </dt>
                <dd>
                  <ul>
                    <li>
                      Create and maintain UI for an educational game coding web
                      application
                    </li>
                    <li>
                      Integrate third party chat and notification libraries
                    </li>
                    <li>
                      HTML, Sass, TailwindCSS, JavaScript, TypeScript, React,
                      Storybook, Git, GraphQL
                    </li>
                  </ul>
                </dd>
                <dt>
                  <strong>Development Lead</strong> | Pivot Design<br />
                  <small><em>September 2019 to July 2020</em></small>
                </dt>
                <dd>
                  <ul>
                    <li>
                      Architect, build, maintain and refactor client and
                      internal web sites
                    </li>
                    <li>
                      Lead a small team to meet tight deadlines on multiple,
                      concurrent projects
                    </li>
                    <li>
                      Research and prototype methods to improve development
                      efficiency
                    </li>
                    <li>
                      Contributed to projects including law, pharmaceuticals,
                      and real estate
                    </li>
                    <li>
                      HTML, CSS / Sass, JavaScript / jQuery, WordPress, PHP,
                      Git, Sketch
                    </li>
                  </ul>
                </dd>
                <dt>
                  <strong>UI Developer</strong> | crowdSPRING, LLC<br />
                  <small><em>September 2007 to September 2016</em></small>
                </dt>
                <dd>
                  <ul>
                    <li>
                      Built company websites in HTML, CSS, JavaScript, Django,
                      Python
                    </li>
                    <li>
                      Assisted management, marketing and customer service as
                      needed
                    </li>
                    <li>
                      Plan and rebuild primary site using Node.js, React.js,
                      Sass and Django
                    </li>
                  </ul>
                </dd>
              </dl>
            </div>
            <div
              className="col-12 col-md-12 col-lg-5 col-xl-4 px-5 pb-5 bg-dingley-90"
            >
              <div className="mb-5">
                <h4 className="font-code">#Skills</h4>
                <p>
                  <strong><em>Primary:</em></strong>
                  <span className="font-code ms-2">
                    #HTML, #CSS, #Sass, #JavaScript, #TypeScript, #React,
                    #jQuery, #Webflow, #WordPress, #Git, #Photoshop
                  </span>
                </p>
                <p>
                  <strong><em>Secondary:</em></strong>
                  <span className="font-code ms-2">
                    #PHP, #Rails, #Node.js, #NextJS, #Sketch, #Figma,
                    #Storybook, #GraphQL, #TailwindCSS, #MaterialUI
                  </span>
                </p>
              </div>
              <div className="mb-5">
                <h4 className="font-code">#Contact</h4>
                <p>Contact info available <a href="#hireme">upon request</a></p>
                <p>
                  And here are some examples of my recent {" "}
                  <Link to="/work">work</Link>.
                </p>
              </div>
              <div>
                <p className="fs-5">
                  <a
                    className="btn btn-light"
                    href="/assets/docs/CJDetmer_Resume_SFE_Developer_Jan2025.pdf"
                    download
                    >Download PDF <i className="bi bi-download ms-2"></i
                  ></a>
                </p>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col">
              <p className="fs-3 text-center align-self-end">
                <Link to="/#hireme"><i className="bi bi-arrow-down-circle"></i></Link>
              </p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}